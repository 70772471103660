import { Component, OnInit } from '@angular/core';
import {  NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../global.service';
import {ModalController, NavParams} from '@ionic/angular';
import { ModalkalenderPage } from '../modalkalender/modalkalender.page';


@Component({
  selector: 'app-modal-fehltag',
  templateUrl: './modal-fehltag.page.html',
  styleUrls: ['./modal-fehltag.page.scss'],
})
export class ModalFehltagPage implements OnInit {
  vedit: Observable<any>;

  public md5uname = localStorage.getItem("md5uname");
  public pass     = localStorage.getItem("pass");


  public editdatum     = localStorage.getItem("editFehltagDatum");
  public editnummer     = localStorage.getItem("editFehltagNummer");
  public editbeschreibung     = localStorage.getItem("editFehltagBeschreibung");


  public ausEditFehltag = this.global.sprache[this.global.as].ausEditFehltag;
  public ausBeschreibung = this.global.sprache[this.global.as].beschreibung;
  public ausDatumFehltag = this.global.sprache[this.global.as].ausDatumFehltag;


  public datmax="";
  constructor(private modalController: ModalController, public global: GlobalService, public navCtrl: NavController,  public httpClient: HttpClient, private navParams: NavParams) {

  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    //console.log('ionViewDidLoad ModalFehltagPage');

    var jahr= new Date().getFullYear() ;

    jahr=jahr+1;

    this.datmax=jahr+"-12-31";
    

  }

  async openKalender(gebdat) {
    const modalPage = await this.modalController.create({
      component: ModalkalenderPage,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
      componentProps: {
        value: gebdat
      }
    });
  
    modalPage.onDidDismiss()
    .then((erg ) => {
      if (erg !== null) {
        let ergebnis = erg['data']['ergebnis'];  
        this.editdatum=ergebnis; 
  
      }
    });
    
    return await modalPage.present();
  }

  async closeModalPage() {
    let data = {'sportart' : ""};
    await this.modalController.dismiss(data);
  }

  public closeModal() {
    //Jetzt abspeichern
    this.vedit = this.httpClient.get(this.global.serverpfad+'supabasePlanungenFehltageChange.php?uname='+this.md5uname+'&id='+this.pass+'&changenummer='+this.editnummer+'&datum='+this.editdatum+'&beschreibung='+this.editbeschreibung);
    this.vedit.subscribe(
		data => {
      this.closeModalPage();
		},
		error => {
      this.closeModalPage();
		}
    );


    
  }



}
