import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { HttpClient } from '@angular/common/http';
import { ActionSheetController,NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-infodesk',
  templateUrl: './infodesk.component.html',
  styleUrls: ['./infodesk.component.scss'],
})
export class InfodeskComponent implements OnInit {

  vmails: Observable<any>;
  vtipps: Observable<any>;
  vtippsSetzen: Observable<any>;

  public md5uname : string = localStorage.getItem("md5uname");
	public pass: string = localStorage.getItem("pass");
  currentPage: string="";
  ausHeadline: string="";
  public itemsTipps=[];
  anzahlTipps=0;


  ausTrainingstipps=this.global.sprache[this.global.as].ausTrainingstipps;
  ausTippAbzeichnen=this.global.sprache[this.global.as].ausTippAbzeichnen;


  constructor(public alertCtrl: AlertController, private router: Router, public modalCtrl : ModalController, public navCtrl: NavController,private actionSheetController: ActionSheetController, public global: GlobalService, public httpClient: HttpClient) { }

  ngOnInit() {
   
    this.md5uname = localStorage.getItem("md5uname");
	  this.pass= localStorage.getItem("pass");

    //Tipps anlegen
    this.setzeTipps();
    
    this.itemsTipps=[];

    //Daten laden
    this.checkFirstVisit();
 
  }

 
  checkFirstVisit() {
    const firstVisit = localStorage.getItem('firstVisit');

    if (firstVisit) {
      const firstVisitDate = new Date(firstVisit);
      const currentDate = new Date();

      // Unterschied in Tagen berechnen
      const diffInMs = currentDate.getTime() - firstVisitDate.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays >= 2) {
        this.ladeTipps();
      }
    } else {
      // Heutiges Datum in localStorage speichern
      localStorage.setItem('firstVisit', new Date().toISOString());
    }
  }


  updateData() {
    // Logik zum Aktualisieren der Daten in Infodesk

    this.checkFirstVisit();
  }

  async abzeichnenTipps(tipp,wert,buttontext) {
    //Den Hinweis erstellen
    var ausgabe=this.ausTippAbzeichnen+"&quot;"+buttontext+"&quot;";

    const confirm = await this.alertCtrl.create({
      header: this.global.sprache[this.global.as].hinweis,
      message: ausgabe,
      buttons: [
        {
        text: this.global.sprache[this.global.as].nein,
        handler: () => {
          //console.log('nein clicked');
        }
        },
        {
        text: this.global.sprache[this.global.as].ja,
        handler: () => {
          this.vtipps = this.httpClient.get(this.global.serverpfad+'supabaseTippAbzeichnen.php?hash='+this.md5uname+"&id="+this.pass+"&tipp="+tipp+"&wert="+wert);
          this.vtipps
          .subscribe(
          data => {
        

            this.ladeTipps(); 

          },
          error => { 
            this.global.pE(error);
          }
          );
          
          //Ende Handler "Ja"
        }
        }
      ]
      });
      await confirm.present();


    
  }

  ladeTipps() {
    
    this.vtipps = this.httpClient.get(this.global.serverpfad+'supabaseTipps.php?hash='+this.md5uname+"&id="+this.pass+"&appversion="+this.global.appVersion+"&zu="+Math.random() );
    this.vtipps
    .subscribe(
    data => {
      this.itemsTipps=data;
      //alert(JSON.stringify(data));
    },
    error => { 
      this.global.pE(error);
      //alert(JSON.stringify(error));
    }
    );
  }

  setzeTipps() {
    this.vtippsSetzen = this.httpClient.get(this.global.serverpfad+'supabaseTippsErstellen.php?hash='+this.md5uname+"&id="+this.pass+"&appversion="+this.global.appVersion+"&zu="+Math.random() );
    this.vtippsSetzen
    .subscribe(
    data => {
      //alert(JSON.stringify(data));
    },
    error => { 
      this.global.pE(error);
      //alert(JSON.stringify(error));
    }
    );
  }



  ladeNachrichten() {
  
    this.vmails = this.httpClient.get(this.global.serverpfad+'supabaseMailsVideos.php?uname='+this.md5uname+"&id="+this.pass);
    this.vmails
		.subscribe(
		data => {
     
      if (data) {
        localStorage.setItem("speichermails", JSON.stringify(data) );
        
      }
      
		},
		error => {
      this.global.pE(error); 
		}
		);
  }

}
